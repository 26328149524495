<template>
  <div class="about">
    <div class="content">
      <h1>Contactenos</h1>
    </div>
    <div class="info container">
      <div class="title">N CONSULTORIA EDUCATIVA CHINA ESPANNA S.L</div>
      <div class="in">联系地址：{{ info.address }}</div>
      <div class="in">电话(西班牙):{{ info.telSpanis }}</div>
      <div class="in">电话(中国):{{ info.tel }}</div>
      <div class="in">微信：{{ info.wechat }}</div>
      <div class="in">微信公众号：{{ info.wechatgongz }}</div>
      <div><img :src="info.bg" /></div>

      <img :src="info.logo" />
    </div>
  </div>
</template>
<script>
import { getShop } from "@/api/user.js"; //引入文件

export default {
  data() {
    return {
      info: {},
    };
  },
  methods: {
    getInfo() {
      getShop().then((res) => {
        this.info = res.data.data;
      });
    },
  },
  created() {
    this.getInfo();
  },
};
</script>
<style lang="scss" scoped>
.content {
  width: 100%;
  padding: 30px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: rgb(46, 46, 46);
  color: #fff;
  h1 {
    //   width: 100%;
    border-bottom: 2px solid #fff;
    padding-bottom: 20px;
  }
}
.info {
  //     padding: 50px 0;
  width: 500px;
  margin: 100px auto;
  img {
    width: 500px;
    height: 200px;
    margin-bottom: 20px;
  }
  @media (max-width: 769px) {
    width: 100%;
    margin: 100px auto;
    img {
      width: 100%;
      height: 200px;
      margin-bottom: 20px;
    }
  }
  .title {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
  }
  .in {
    margin-bottom: 20px;
  }
}
</style>